.header-template-landing-style {
  /* padding-top: 50px; */
  /* margin-bottom: 50px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divisor-header-template {
  border: 1px solid rgba(196, 196, 196, 1);
  width: 90%;
  margin-bottom: 50px;
}

iframe.templateContainer {
  transform: scaleX(0.219444) scaleY(0.174375) !important;
}